export const DO_NOT_SHOW = 'DO NOT SHOW';
export const MONTHLY = 'MONTHLY';
export const mapDoNotShowToMonthly = externalRecurringChargeFrequency => (externalRecurringChargeFrequency === DO_NOT_SHOW ? MONTHLY : externalRecurringChargeFrequency);

export const CYCLE_MAPPING = {
  YE: 'annually',
  MON: 'monthly',
};

export const MAP_CHARGE_FREQUENCY_LOCALE = {
  ANNUALLY: 'perYearLocale',
  MONTHLY: 'perMonthLocale',
  [DO_NOT_SHOW]: 'perMonthLocale',
  default: 'perMonthLocale',
};
export const getPerTimeLabel = externalRecurringChargeFrequency => MAP_CHARGE_FREQUENCY_LOCALE[externalRecurringChargeFrequency] || null;

export const MAP_CHARGE_FREQUENCY_LOCALE_UNIT = {
  ANNUALLY: 'yearsCycleLocale',
  MONTHLY: 'monthCycleLocale',
  [DO_NOT_SHOW]: 'monthCycleLocale',
  default: 'monthCycleLocale',
};
export const getPerTimeLabelUnit = externalRecurringChargeFrequency => MAP_CHARGE_FREQUENCY_LOCALE_UNIT[externalRecurringChargeFrequency] || null;
